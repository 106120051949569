import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  unblockWrapper: {
    width: "290px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      display: "inline",
      width: "174px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      width: "290px",
    },
  },
  unblock: {
    fontFamily: "SofiaProRegular",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#22272B",
    marginTop: "8px",
  },
  descriptionWrapper: { width: "555px" },
  description: {
    fontFamily: "SofiaProRegular",
    color: "#9292A8",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: "16px",
      lineHeight: "28px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
}));

const Description = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.unblockWrapper}>
        <Typography className={classes.unblock}>Pondering.io</Typography>
      </div>

      <div className={classes.descriptionWrapper}>
        <Typography className={classes.description}>
          A mix and match ideation tool, use these cards to surface and explore
          novel combinations. Shuffle, surface, explore — give it a try.
        </Typography>
      </div>
    </div>
  );
};

export default Description;
