import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import HeaderLogos from "./headerLogos";
import Description from "./description";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      margin: "25px 20px 34px 20px"
    },
    [theme.breakpoints.between("sm", "lg")]: {
      margin: "45px 50px 48px 50px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "80px",
      marginRight: "150px",
      marginLeft: "150px"
    }
  },
  divider: {
    height: "2px",
    backgroundColor: "black",
    marginBottom: "32px"
  },
  description: {
    marginBottom: "56px"
  }
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeaderLogos />
      <Divider className={classes.divider} />
      <div className={classes.description}>
        <Description />
      </div>
    </div>
  );
};

export default Header;
