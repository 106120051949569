import React, { useState } from "react";
import Header from "./components/header";
import CardContainer from "./components/cardContainer";
import GetInTouch from "./components/getInTouch";
import Footer from "./components/footer";
import { Context } from "./components/context";

const { site_name } = require("./config");

function App() {
  if (window.location.host.startsWith(site_name)) {
    window.location = `${window.location.protocol}//www.${window.location.host}${window.location.pathname}`;
    console.log(window.location);
  }
  const [ctaColor, setColor] = useState("#FAFAFA");
  const [patternRand, setPatternRand] = useState(0);
  const [pinned, setPinned] = useState(false);
  const [pinStart, setPinStart] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  const patternRandomInt = (max) => {
    const rand = Math.floor(Math.random() * Math.floor(max));
    setPatternRand(rand);
  };
  return (
    <React.Fragment>
      <Context.Provider
        value={{
          ctaColor,
          setColor,
          patternRand,
          patternRandomInt,
          setPinned,
          pinned,
          pinStart,
          setPinStart,
          buttonHover,
          setButtonHover,
        }}
      >
        <Header />
        <CardContainer patternRand={patternRand} />
        <GetInTouch ctaColor={ctaColor} />
        <Footer />
      </Context.Provider>
    </React.Fragment>
  );
}

export default App;
