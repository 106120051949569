import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Context } from "./context";
import "../styles/styles.css";

import PinButton from "./pinButton";

const useStyles = makeStyles((theme) => ({
  cards: (props) => ({
    backgroundColor: props.backgroundColor,
    marginTop: props.firstCard ? "0px" : "12px",
    display: "inline",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      height: "215px",
      marginLeft: props.firstCard ? "0px" : "10px",
      marginRight: props.firstCard ? "10px" : "0px",
      minWidth: "100%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      marginTop: props.firstCard ? "0px" : "12px",
      minWidth: "100%",
      maxWidth: "100%",
      height: "280px",
      marginLeft: props.firstCard ? "0px" : "15px",
      marginRight: props.firstCard ? "15px" : "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "12px",
      marginBottom: "12px",
      minHeight: "415px",
      marginLeft: "0px",
    },
  }),
  scaledCards: (props) => ({
    backgroundColor: props.backgroundColor,
    marginTop: props.firstCard ? "0px" : "12px",
    display: "inline",
    position: "relative",
    transform: "scale(0.98)",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      height: "215px",
      marginLeft: props.firstCard ? "0px" : "10px",
      marginRight: props.firstCard ? "10px" : "0px",
      minWidth: "100%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      marginTop: props.firstCard ? "0px" : "12px",
      minWidth: "100%",
      maxWidth: "100%",
      height: "280px",
      marginLeft: props.firstCard ? "0px" : "15px",
      marginRight: props.firstCard ? "15px" : "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "12px",
      marginBottom: "12px",
      minHeight: "415px",
      marginLeft: "0px",
    },
  }),

  textWrapper: {
    position: "relative",
    top: "50%",
    left: "50%",
    width: "457px",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      width: "216px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "436px",
    },
  },
  title: (props) => ({
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "SofiaProBold",
    color: props.titleColor,
    marginBottom: "5px",
  }),
  description: (props) => ({
    fontFamily: "SofiaProRegular",
    fontSize: "24px",
    lineHeight: "36px",
    color: props.desColor,
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: "20px",
      lineHeight: "36px",
    },
  }),

  pinButton: {
    position: "absolute",
    top: "21px",
    left: "15px",
  },
}));

const Card = (props) => {
  const classes = useStyles(props);
  const { setPinned, pinned, pinStart, setPinStart } = React.useContext(
    Context
  );
  const handleClick = (e) => {
    setPinStart(true);
    setPinned(!pinned);
    document.getElementById("pinButton").blur();
  };
  const tabletMatch = useMediaQuery("(max-width:1279px)");
  const animationSelector = (pin) => {
    if (props.click) {
      if (tabletMatch) {
        if (props.rightTop) {
          if (pin) {
            return `${classes.scaledCards}`;
          } else {
            return `animationRight ${classes.cards}`;
          }
        } else {
          return `animationLeft ${classes.cards}`;
        }
      } else {
        if (props.rightTop) {
          if (pin) {
            return ` ${classes.scaledCards}`;
          } else {
            return `animationTop ${classes.cards}`;
          }
        } else {
          return `animationBottom ${classes.cards}`;
        }
      }
    } else if (pin && props.firstCard) {
      if (pinStart) {
        return `scaleInAnimation ${classes.cards}`;
      } else {
        return `${classes.scaledCards}`;
      }
    } else if (!pin && props.firstCard) {
      if (pinStart) {
        return `scaleOutAnimation ${classes.cards}`;
      } else {
        return `${classes.cards}`;
      }
    } else {
      return `${classes.cards}`;
    }
  };

  return (
    <div className={animationSelector(pinned)}>
      {props.firstCard && (
        <div className={classes.pinButton}>
          <PinButton onClick={handleClick} pinned={pinned} />
        </div>
      )}
      <div className={classes.textWrapper}>
        <div>
          <Typography className={classes.title}>{props.titleText}</Typography>
        </div>
        <div>
          <Typography className={classes.description}>
            {props.desText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Card;
