import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";

import share_sign from "../images/share_sign.svg";
import share_hovered from "../images/share-hovered.svg";
import cross_sign from "../images/cross_sign.svg";
import facebookLogo from "../images/facebook-black.svg";
import linkedinLogo from "../images/linkedin-black.svg";
import twitterLogo from "../images/twitter-black.svg";

const useStyles = makeStyles(theme => ({
  shareSign: {
    marginTop: "3px",
    marginBottom: "auto"
  },
  shareButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    marginBottom: "auto",
    bottom: "27px",
    "&:focus": {
      outline: 0,
      border: "solid 1px #F25930",
      boxShadow: "0px 0px 5px rgba(242,89,48,1)"
    }
  },
  titleWrapper: {
    display: "flex",
    margin: "60px 50px 40px 50px"
  },
  firstShareText: {
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "SofiaProBold",
    color: "#66646B",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline"
    }
  },
  firstShareText_hovered: {
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "SofiaProBold",
    color: "#F25930",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline"
    }
  },
  shareText: {
    fontSize: "48px",
    lineHeight: "60px",
    fontFamily: "SofiaProBold",
    color: "#22272B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "32px"
    }
  },
  dialog: {
    "& .MuiPaper-rounded": {
      borderRadius: "0px"
    },
    "& .MuiPaper-root": {
      width: "554px",
      height: "409px"
    },
    "& .MuiDialog-paper": {
      overflowY: "visible"
    }
  },
  close: { display: "flex", alignItems: "center", marginLeft: "auto" },
  closeButton: {
    backgroundColor: "transparent",
    border: "none",
    bottom: "27px",
    "&:focus": {
      outline: 0
    },
    padding: "0px"
  },
  closeText: {
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "SofiaProBold",
    color: "#66646B",
    marginRight: "20px"
  },
  crossSign: {
    width: "17px",
    height: "17px"
  },
  linkWrapper: {
    margin: "0px 50px 70px 50px",
    "& .MuiFormControl-root": {
      display: "inline"
    },
    "& .MuiInputBase-root": {
      display: "block",
      paddingBottom: "9px",
      fontFamily: "SofiaProRegular",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#66646B"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid #000000de"
    }
  },
  copyWrapper: {
    marginBottom: "16px"
  },
  copyText: {
    fontFamily: "SofiaProRegular",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#9292A8"
  },
  input: {
    marginBottom: "50px"
  },
  socialWrapper: {
    marginBottom: "20px"
  },
  socialText: {
    fontFamily: "SofiaProRegular",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#9292A8"
  },
  socialButton: {
    marginRight: "40px",
    "&:focus": {
      outline: 0
    }
  }
}));

const ShareDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [share, setshare] = React.useState(false);
  let host = window.location.host;
  let protocol = window.location.protocol;
  let shareLink = `${protocol}//${host}`;
  const handleClickOpen = () => {
    setOpen(true);
    document.getElementById("shareButton").blur();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = () => {
    setshare(true);
  };
  const handleMouseLeave = () => {
    setshare(false);
  };

  return (
    <div>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classes.shareButton}
        onClick={handleClickOpen}
        id="shareButton"
      >
        <Typography
          className={
            share ? classes.firstShareText_hovered : classes.firstShareText
          }
        >
          SHARE
        </Typography>
        <img
          src={share ? share_hovered : share_sign}
          className={classes.shareSign}
          alt="share sign"
        />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
        disableBackdropClick={true}
      >
        <div className={classes.titleWrapper}>
          <Typography className={classes.shareText}>Share</Typography>

          <div className={classes.close}>
            <Typography className={classes.closeText}>CLOSE</Typography>
            <button className={classes.closeButton} onClick={handleClose}>
              <img
                src={cross_sign}
                className={classes.crossSign}
                alt="cross sign"
              />
            </button>
          </div>
        </div>

        <div className={classes.linkWrapper}>
          <div className={classes.copyWrapper}>
            <Typography className={classes.copyText}>Copy URL</Typography>
          </div>
          <div className={classes.input}>
            <TextField defaultValue={`${shareLink}`} />
          </div>
          <div className={classes.socialWrapper}>
            <Typography className={classes.socialText}>Social</Typography>
          </div>
          <div>
            <TwitterShareButton
              url={`${shareLink}`}
              className={classes.socialButton}
            >
              <img src={twitterLogo} alt="Twitter Logo" />
            </TwitterShareButton>
            <FacebookShareButton
              url={`${shareLink}`}
              className={classes.socialButton}
            >
              <img src={facebookLogo} alt="Facebook Logo" />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`${shareLink}`}
              className={classes.socialButton}
            >
              <img src={linkedinLogo} alt="Linkedin Logo" />
            </LinkedinShareButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ShareDialog;
