module.exports = {
  ctaColorList: [
    "#FFF7ED",
    "#FEF2EF",
    "#FEF9EE",
    "#F5F3F8",
    "#FCEFF2",
    "#EEF5FC",
    "#F1FAFB",
    "#F7FAF0",
    "#F1FAF3"
  ],
  MPColorList: [
    "#FF8F17",
    "#F25930",
    "#ECB027",
    "#7B5EA7",
    "#D5315C",
    "#247BD1",
    "#47C1C3",
    "#93BD3E",
    "#4AB763"
  ]
};
