import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Orium_Logo from "../images/orium-logo.svg";
import plus_sign from "../images/plus_sign.svg";
import ShareDialog from "./shareDialog";
import GetInTouchButton from "./getInTouchButton";
import { Context } from "./context";

const contentful = require("contentful");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  oriumLogo: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "21px",
      maxHeight: "25px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "34px",
    },
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
    },
  },
  partnerLogo: {
    height: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1px",
      maxHeight: "25px",
      marginLeft: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "30px",
      marginBottom: "30px",
      marginLeft: "24px",
    },
  },
  plusSign: {
    marginLeft: "24px",
    marginBottom: "34px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "21px",
      marginLeft: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "24px",
      marginBottom: "42px",
    },
  },
  shareContainer: {
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
      marginLeft: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "28px",
      marginLeft: "0px",
    },
  },
  shareText: {
    fontSize: "12px",
    lineHeight: "20px",
    fontFamily: "SofiaProBold",
    color: "#66646B",
    marginTop: "6px",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "inline",
    },
  },
  getInTouchButton: {
    marginLeft: "auto",
    marginBottom: "27px",
    textDecoration: "none",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      display: "inline",
      marginRight: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
      marginRight: "33px",
    },
  },
}));

const HeaderLogos = () => {
  const [partnerLogo, setPartnerLogo] = useState(null);
  const { buttonHover } = useContext(Context);
  let host = window.location.host;
  let parts = host.split(".");
  let siteSubdomain = "";
  if (parts.length >= 3) {
    siteSubdomain = parts[0];
  }
  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({ content_type: "partnersLogos" })
      .then((contentType) => {
        let result = contentType.items[0].fields.partners.filter((result) => {
          return result.fields.subdomain === siteSubdomain;
        });
        if (result.length !== 0) {
          setPartnerLogo(result[0].fields.logo.fields.file.url);
        }
      })
      .catch(console.error);
  }, [siteSubdomain]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logos}>
        <img
          src={Orium_Logo}
          className={classes.oriumLogo}
          alt="Orium logo"
        />

        {partnerLogo && (
          <img className={classes.plusSign} src={plus_sign} alt="plus sign" />
        )}
        {partnerLogo && (
          <img
            src={partnerLogo}
            className={classes.partnerLogo}
            alt="Partner logo"
          />
        )}
      </div>
      <a
        className={classes.getInTouchButton}
        href="https://orium.com/contact"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GetInTouchButton buttonHover={buttonHover} text="Get in touch" />
      </a>
      <div className={classes.shareContainer}>
        <ShareDialog />
      </div>
    </div>
  );
};

export default HeaderLogos;
