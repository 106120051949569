import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import pinSign from "../images/pin_sign.svg";
import pin_pinned from "../images/Pin_Icon_Pinned.svg";

const useStyles = makeStyles({
  root: props => ({
    width: "52px",
    height: "52px",
    borderRadius: "100%",
    border: props.pinned ? "solid #F25930 1.5px" : "none",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    "&:focus": {
      outline: 0,
      boxShadow: "0px 0px 20px rgba(242,89,48,1)",
      border: "solid #F25930 1.5px"
    },
    "&:hover": {
      border: "solid #F25930 1.5px"
    }
  }),
  pinSign: {
    width: "20px",
    height: "20px"
  }
});

const PinButton = props => {
  const classes = useStyles(props);
  return (
    <button
      className={classes.root}
      id="pinButton"
      onClick={props.onClick}
      variant="outlined"
    >
      <img
        src={props.pinned ? pin_pinned : pinSign}
        className={classes.pinSign}
        alt="Pin Sign"
      />
    </button>
  );
};

export default PinButton;
