import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CTAPattern from "../images/CTApattern.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../styles/styles.css";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: "relative",
    backgroundColor: "#FFF5F2",
    left: "-65px",
    display: "block",
    marginRight: "-65px",
    objectFit: "contain",
    paddingTop: "65px",
    paddingBottom: "68px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "45px 20px 45px 79px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: "65px 0px 68px 115px",
      height: "36px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "65px 0px 68px 115px",
      height: "36px",
    },
  }),
  slideRoot: (props) => ({
    position: "relative",
    backgroundColor: "#FFF5F2",
    left: "-65px",
    display: "block",
    paddingTop: "65px",
    paddingBottom: "68px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "45px 20px 45px 144px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: "65px 0px 68px 115px",
      height: "36px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "65px 0px 68px 115px",
      height: "36px",
    },
  }),

  text: {
    fontFamily: "SofiaProRegular",
    display: "inline",
    position: "relative",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#22272B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "23px",
    },
  },
  link: {
    "&:focus": {
      outline: "none",
    },
    textDecoration: "none",
  },
  pattern: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  img: {
    height: "169px",
    width: "65px",
    [theme.breakpoints.down("sm")]: {
      height: "136px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      height: "169px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "169px",
    },
  },
  textWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "236px",
      display: "inline",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      minWidth: "476px",
      display: "inline",
    },
    [theme.breakpoints.up("lg")]: {
      width: "651px",
      display: "inline",
    },
  },
  getInTouch: {
    color: "#F25930",
    textDecoration: "underline",
  },
}));

const GetInTouch = (props) => {
  const classes = useStyles(props);
  const mobileMatch = useMediaQuery("(max-width:600px)");

  const [slideStart, setSlideStart] = useState(false);

  const [slide, setSlide] = useState(false);
  const handleMouseEnter = () => {
    setSlide(true);
    setSlideStart(true);
  };
  const handleMouseLeave = () => {
    setSlide(false);
  };
  return (
    <a
      href="https://orium.com/contact"
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={
          slideStart
            ? !mobileMatch
              ? slide
                ? `slideAnimation ${classes.slideRoot}`
                : `slideReverseAnimation ${classes.root}`
              : classes.root
            : classes.root
        }
      >
        <div className={classes.pattern}>
          <img src={CTAPattern} className={classes.img} alt="CTA Pattern" />
        </div>
        <div className={classes.textWrapper}>
          <Typography className={classes.text}>
            Let’s discuss your next project.{" "}
            <span className={classes.getInTouch}>Get in touch</span>.
          </Typography>
        </div>
      </div>
    </a>
  );
};

export default GetInTouch;
