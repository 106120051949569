import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "./shuffleButton";
import Card from "./card";

import { Context } from "./context";
import { ctaColorList, MPColorList } from "../colors";
import patterns from "../images/CartContainerPatterns/cartContainerPatterns";
import Orium_Pattern from "../images/orium-pattern.png";
import "../styles/styles.css";

const contentful = require("contentful");

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
    height: "439px",
    maxWidth: "100vw",
    backgroundImage: `url(${Orium_Pattern})`,
    bottom: 0,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "10px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      flexDirection: "column",
      padding: "15px",
      height: "577px",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      height: "439px",
      maxWidth: "100vw",
      padding: "0px",
    },
  }),
  cardsRightBottom: (props) => ({
    position: "absolute",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "row-reverse",
      position: "relative",
      overflow: "hidden",
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingRight: "10px",
      paddingLeft: "10px",
    },
  }),
  cardsLeftTop: (props) => ({
    position: "absolute",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      overflow: "hidden",
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "column-reverse",
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingLeft: "10px",
    },
  }),
  button: {
    position: "absolute",
    zIndex: "100",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const CardContainer = (props) => {
  const [click, setClick] = useState(true);
  const [firstClick, setFirstClick] = useState(true);
  const [howMightEntries, setHowMightEntries] = useState([]);
  let [firstHowMightItem, setFirstHowMightItem] = useState([]);
  let [firstSoThatItem, setFirstSoThatItem] = useState([]);
  const [soThatEntries, setSoThatEntries] = useState([]);
  const [howMightCardColor, setHowMightCardColor] = useState(0);
  const [howMightColor, setHowMightColor] = useState(2);
  const [soThatCardColor, setSoThatCardColor] = useState(3);
  const [soThatColor, setSoThatColor] = useState(1);
  const { setColor, patternRandomInt, pinned, setPinStart } = useContext(
    Context
  );
  const classes = useStyles(props);
  const [shuffleStart, setShuffleStart] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const handleMouseEnter = () => {
    setShuffle(true);
    setShuffleStart(true);
  };
  const handleMouseLeave = () => {
    setShuffle(false);
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client
      .getEntries({ content_type: "howMightItem" })
      .then((contentType) => {
        let temp = contentType.items;
        temp.push(contentType.items[0]);
        setHowMightEntries(contentType.items);
        setFirstHowMightItem(temp);
      })
      .catch(console.error);

    client
      .getEntries({ content_type: "soThatItem" })
      .then((contentType) => {
        let temp = contentType.items;
        temp.push(contentType.items[0]);
        setSoThatEntries(contentType.items);
        setFirstSoThatItem(temp);
      })
      .catch(console.error);
  }, []);

  if (firstClick) {
    let howMightRandom = howMightEntries.length - 1;
    let soThatRandom = soThatEntries.length - 1;
    document.documentElement.style.setProperty(
      "--so-that-bottom",
      `${soThatRandom * 439}px`
    );

    document.documentElement.style.setProperty(
      "--how-might-top",
      `${howMightRandom * 439}px`
    );
    if (window.innerWidth <= 600) {
      document.documentElement.style.setProperty(
        "--how-might-right",
        `${howMightRandom * (window.innerWidth - 10)}px`
      );
      document.documentElement.style.setProperty(
        "--so-that-left",
        `${soThatRandom * (window.innerWidth - 10)}px`
      );
    }
    if (window.innerWidth > 600 && window.innerWidth <= 1279) {
      document.documentElement.style.setProperty(
        "--how-might-right",
        `${howMightRandom * (window.innerWidth - 15)}px`
      );
      document.documentElement.style.setProperty(
        "--so-that-left",
        `${soThatRandom * (window.innerWidth - 15)}px`
      );
    }
    setTimeout(() => {
      if (soThatEntries.length !== 0) {
        setFirstSoThatItem([soThatEntries[0]]);
      }
      if (howMightEntries.length !== 0) {
        setFirstHowMightItem([howMightEntries[0]]);
      }
      setFirstClick(false);
      setClick(false);
    }, 4000);
  }

  const handleClick = (e) => {
    document.getElementById("shuffleButton").blur();
    let howMightRandom = getRandomInt(howMightEntries.length);
    let soThatRandom = getRandomInt(soThatEntries.length);
    setFirstSoThatItem(firstSoThatItem.concat(soThatEntries));
    if (!pinned) {
      setFirstHowMightItem(firstHowMightItem.concat(howMightEntries));
    }
    document.documentElement.style.setProperty(
      "--so-that-bottom",
      `${(soThatRandom + 1) * 439}px`
    );

    document.documentElement.style.setProperty(
      "--how-might-top",
      `${(howMightRandom + 1) * 439}px`
    );
    if (window.innerWidth <= 600) {
      document.documentElement.style.setProperty(
        "--how-might-right",
        `${(howMightRandom + 1) * (window.innerWidth - 10)}px`
      );
      document.documentElement.style.setProperty(
        "--so-that-left",
        `${(soThatRandom + 1) * (window.innerWidth - 10)}px`
      );
    }
    if (window.innerWidth > 600 && window.innerWidth <= 1279) {
      document.documentElement.style.setProperty(
        "--how-might-right",
        `${(howMightRandom + 1) * (window.innerWidth - 15)}px`
      );
      document.documentElement.style.setProperty(
        "--so-that-left",
        `${(soThatRandom + 1) * (window.innerWidth - 15)}px`
      );
    }

    setClick(true);
    setTimeout(() => {
      let howTemp = howMightEntries[howMightRandom];
      let soTemp = soThatEntries[soThatRandom];
      if (!pinned) {
        setFirstHowMightItem([howTemp]);
      }
      setFirstSoThatItem([soTemp]);
      if (!pinned) {
        setColor(ctaColorList[howMightRandom % ctaColorList.length]);
      }
      if (!pinned) {
        setHowMightCardColor(
          getRandomInt(howMightEntries.length) % ctaColorList.length
        );
      }
      if (!pinned) {
        setHowMightColor(
          getRandomInt(howMightEntries.length) % MPColorList.length
        );
      }
      setSoThatCardColor(
        getRandomInt(soThatEntries.length) % ctaColorList.length
      );
      setSoThatColor(getRandomInt(soThatEntries.length) % MPColorList.length);
      patternRandomInt(patterns.length);
      setPinStart(false);
      setClick(false);
    }, 3000);
  };
  return (
    <div className={`${classes.root}`}>
      <div className={classes.cardsLeftTop}>
        {firstHowMightItem.map((entry, index) => (
          <Card
            firstCard
            key={index}
            backgroundColor={ctaColorList[howMightCardColor]}
            titleText="HOW MIGHT…"
            desText={entry.fields.howMightItemText}
            titleColor={MPColorList[howMightColor]}
            desColor="#22272B"
            click={click}
            rightTop
          />
        ))}
      </div>
      <div className={classes.button}>
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          text="SHUFFLE"
          onClick={handleClick}
          shuffle={shuffle}
          shuffleStart={shuffleStart}
        />
      </div>
      <div className={classes.cardsRightBottom}>
        {firstSoThatItem.map((entry, index) => (
          <Card
            key={index}
            backgroundColor={ctaColorList[soThatCardColor]}
            titleText="SO THAT…"
            desText={entry.fields.soThatItemText}
            titleColor={MPColorList[soThatColor]}
            desColor="#22272B"
            click={click}
          />
        ))}
      </div>
    </div>
  );
};

export default CardContainer;
