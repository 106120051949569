import BG_Hills from "./BG_Hills.jpg";
import BG_Indigo from "./BG_Indigo.jpg";
import BG_Molecules from "./BG_Molecules.jpg";
import BG_Playground from "./BG_Playground.jpg";
import BG_Quilt from "./BG_Quilt.jpg";
import BG_Streams from "./BG_Streams.jpg";
import BG_Waterfall from "./BG_Waterfall.jpg";
import BG_Waves from "./BG_Waves.jpg";

const patterns = [
  BG_Hills,
  BG_Indigo,
  BG_Molecules,
  BG_Playground,
  BG_Quilt,
  BG_Streams,
  BG_Waterfall,
  BG_Waves
];

export default patterns;
