import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import { Context } from "./context";

import "../styles/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root:hover": {
      backgroundColor: "FFFFFF",
    },
  },
  button: {
    width: "157px",
    height: "52px",
    borderRadius: "30px",
    border: "solid 2px rgba(242,89,48,1)",
    backgroundColor: "FFFFFF",
    "&:hover": {
      backgroundColor: "#F25930",
    },
    "&:focus": {
      outline: 0,
      boxShadow: "0px 0px 20px rgba(242,89,48,1)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "132px",
      height: "40px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "142px",
      height: "40px",
    },
  },
  text: (props) => ({
    fontFamily: "SofiaProBold",
    fontSize: "12px",
    lineHeight: "20px",
    color: props.buttonHover ? "#FFFFFF" : "#F25930",
    "&:hover": {
      color: "#FFFFFF",
    },
  }),
}));

const GetInTouchButton = (props) => {
  const classes = useStyles(props);
  const { setButtonHover } = useContext(Context);

  const handleClick = () => {
    document.getElementById("getInTouchButton").blur();
  };

  const handleMouseEnter = () => {
    setButtonHover(true);
  };
  const handleMouseLeave = () => {
    setButtonHover(false);
  };
  return (
    <div className={classes.root}>
      <Button
        id="getInTouchButton"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        disableFocusRipple={true}
        disableRipple={true}
        className={classes.button}
        variant="outlined"
      >
        <Typography className={classes.text}>{props.text}</Typography>
      </Button>
    </div>
  );
};

export default GetInTouchButton;
