import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import shuffle from "../images/Shuffle.svg";
import "../styles/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root:hover": {
      backgroundColor: "#22272B",
    },
  },
  button: {
    width: "157px",
    height: "52px",
    borderRadius: "1000px",
    border: "none",
    backgroundColor: "#22272B",
    "&:focus": {
      outline: 0,
      boxShadow: "0px 0px 20px rgba(0,0,0,0.5)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "146px",
      height: "44px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "189px",
      height: "63px",
    },
  },
  text: {
    fontFamily: "SofiaProBold",
    fontSize: "15px",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginLeft: "16px",
  },

  shuffleSign: {
    marginTop: "5px",
    width: "24px",
  },
}));

const CostumButton = (props) => {
  const classes = useStyles(props);
  return (
    <div
      className={
        props.shuffleStart
          ? props.shuffle
            ? `shuffleScaleAnimation ${classes.root}`
            : `shuffleScaleReverseAnimation ${classes.root}`
          : classes.root
      }
    >
      <Button
        id="shuffleButton"
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        disableFocusRipple={true}
        disableRipple={true}
        className={classes.button}
        variant="outlined"
      >
        <div>
          <img
            className={
              props.shuffleStart
                ? props.shuffle
                  ? `shuffleAnimation ${classes.shuffleSign}`
                  : `shuffleReverseAnimation ${classes.shuffleSign}`
                : classes.shuffleSign
            }
            src={shuffle}
            alt="Shuffle Sign"
          />
        </div>
        <Typography className={classes.text}>{props.text}</Typography>
      </Button>
    </div>
  );
};

export default CostumButton;
