import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Orium_Logo from "../images/orium-logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "36px 0px 36px 24px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      flexDirection: "column",
      padding: "42px 0px 45px 108px",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      padding: "48px 72px 50px 72px",
    },
  },
  logoWrapper: {
    [theme.breakpoints.down("lg")]: {
      width: "98px",
      height: "18px",
      marginBottom: "24px",
      marginRight: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "317px",
    },
  },
  copyWrapper: {
    [theme.breakpoints.down("lg")]: {
      marginBottom: "24px",
      width: "218px",
    },

    [theme.breakpoints.up("lg")]: {
      marginRight: "40px",
      width: "395px",
    },
  },
  copyText: {
    fontFamily: "PoppinsRegular",
    color: "#1B1B33",
    [theme.breakpoints.down("lg")]: {
      fontSize: "13px",
      lineHeight: "22px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  linksWrapper: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      marginLeft: "auto",
    },
  },
  link: {
    textDecoration: "none",
    color: "#1B1B33",
    [theme.breakpoints.down("lg")]: {
      marginRight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "40px",
    },
    "&:focus": {
      outline: "none",
    },
  },
  linkText: {
    fontFamily: "PoppinsRegular",
    [theme.breakpoints.down("lg")]: {
      fontSize: "13px",
      lineHeight: "22px",
      textAlign: "left",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  lastLink: {
    textDecoration: "none",
    color: "#1B1B33",
    [theme.breakpoints.down("lg")]: {
      marginRight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0px",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoWrapper}>
        <img src={Orium_Logo} alt="Orium Logo" />
      </div>
      <div className={classes.copyWrapper}>
        <Typography className={classes.copyText}>
          &copy;Copyright 2022 Orium All Rights Reserved.
        </Typography>
      </div>
      <div className={classes.linksWrapper}>
        <a href="https://orium.com/privacy-policy" className={classes.link}>
          <Typography className={classes.linkText}>Privacy Policy</Typography>
        </a>
        <a href="https://orium.com/contact" className={classes.lastLink}>
          <Typography className={classes.linkText}>Contact</Typography>
        </a>
      </div>
    </div>
  );
};

export default Footer;
